<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "BuildingBusiness",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Building your business",
          Subtitle: "Put the right blocks in place",
          Description: "From setting up and registering your company, to day to day governance issues, NLA can help you build the business that you’re working towards.",
          img: require("@/assets/img/Natalie.jpg"),
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: `<p>We understand the intricacies involved in starting and running a company, and can help you navigate the legal, financial and contractual pitfalls with clarity and confidence. &nbsp;We also take cognisance of the relationships you’ll need to forge, such as contractual arrangements with suppliers, clients, partners, shareholders, directors, and employees, so that disputes are avoided and expectations set. <br></p>
            <p>Our services help you to structure a business approach that best suits your needs, and eliminate uncertainties throughout the process, such as:</p>
            <ul>
              <li>Registering various companies; </li>
              <li>Implementing the required Structure and Strategy</li>
              <li>Advising on Broad Based Black Economic Empowerment provisions and assisting in implementing such structures</li>
              <li>Drafting and Implementing various Business Contracts</li>
              <li>Assisting in various Disputes</li>
              <li>Drafting of Policies; and</li>
              <li>Performing Due Diligences</li>
            </ul>`,
          TextColumn2:
            "<blockquote>Natalie and I worked together on a sale and acquisition in partnership with a private equity fund. During the project, Natalie availed herself for all our meetings, even on short notice, and was dedicated to ensuring we met our deadlines. She always provided great insight and considerations during the project.<br><br>Her positive attitude and personality make her a pleasure to work with.”<br><br>- Melissa Gangen, Director, Digital Planet</blockquote>",
        },
        {
          Profiles: true,
          Name: "Talk to Us",
          Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true,
            },
            {
              Title: "Laura Sibanda",
              Subtitle: "Candidate Attorney",
              Linkedin: "linkedin.com/in/laurasibanda",
              Email: "laura@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Laura-bio.jpg"),
              left: false,
              last: true,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
